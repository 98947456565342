import React, { useEffect, useRef, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Creatable from "react-select/creatable";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { isArray } from "lodash";
import { Modal } from "react-bootstrap";
import axios from "axios";
import toastr from "toastr";
import "./style.scss";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import {
  checkObjectIdValid,
  getLocalbody,
  getUserId,
} from "../../../helpers/globalFunctions";

import { post, put } from "../../../helpers/api_helper";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
const Complaints = () => {
  const localbody = getLocalbody();
  const formRef = useRef();
  const PopUpRef = useRef();
  const initialState = {
    localbodyId: getLocalbody(),
    userId: getUserId(),
    image: [""],
  };

  const followupState = {
    localbodyId: getLocalbody(),
    userId: getUserId(),
    image: [""],
    status: "",
    comment: "",
  };
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [master, setMaster] = useState(initialState);
  const [filterObject, setFilterObject] = useState({});
  const [filterSelectObject, setFilterSelectObject] = useState({});

  const [followUp, setFollowUp] = useState(followupState);

  const [wardOptions, setWardOptions] = useState([]);
  const [staffComplaintIdToBeDeleted, setStaffComplaintIdToBeDeleted] =
    useState(null);
  const [complaintId, setComplaintId] = useState(null);


  const [groupOptions, setGroupOptions] = useState([]);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [complaintCategoryOptions, setComplaintCategoryOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [assaignedStaff, setAssaignedStaff] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [FollowUpTableData, setFollowUpTableData] = useState([]);
  const [selected, setSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [popupData, setPopupData] = useState({});
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    fetch_all_wards(localbody);
    fetch_all_customers(localbody, "");
    fetch_all_staff();
    fetch_complaint_catagory();
    // handleTableData(localbody);
    fetch_customers();
    fetch_complaint_staff();
    handlePopUpTable();
  }, [localbody, master?.wardId, followUp?.complaintId]);

  useEffect(() => {
    handleTableData(localbody);
  }, [filterObject]);
  useEffect(() => {
    handlePopUpTable();
  }, [followUp?.complaintId]);

  const fetch_all_staff = () => {
    axios
      // .get(`${API_URL}complaint/name/select?wardId=${master.wardId}&type=2`,
      .get(`${API_URL}staff/staff-list?localBody_id=${localbody}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let staff_data = res.data.data;

        setStaffOptions(staff_data);
      });
  };
  const fetch_complaint_staff = () => {
    axios
      .get(
        `${API_URL}complaint/staff/toassign?complaintId=${followUp?.complaintId}&wardId=${master.wardId}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var assaigned_staff =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.firstName + el.lastName,
              value: el._id,
            };
          });

        setAssaignedStaff([
          {
            options: assaigned_staff,
          },
        ]);
      });
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_complaint_catagory = () => {
    axios
      .get(`${API_URL}customercomplaint/categorylist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var complaint_category_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.category_name,
              value: el._id,
            };
          });

        setComplaintCategoryOptions([
          {
            options: complaint_category_data,
          },
        ]);
      });
  };
  const handleTableData = (localbody) => {
    axios
      .get(
        `${API_URL}complaint/crm/list?localbodyId=${localbody}&fromDate=${filterObject.fromDate}&toDate=${filterObject.toDate}&status=${filterObject?.status}&wardId=${filterObject?.wardId}&complaintType=${filterObject?.complaintType}&against=${filterObject?.against}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        const data = res?.data?.data;
        data?.map((item, index) => {
          item.id = index + 1;
          item.data = moment(item?.date).format("DD-MM-YYYY");
          item.from = item?.complaintFrom?.name;
          item.status =
            item?.currentStatus === 0
              ? "Active"
              : item?.currentStatus === 1
                ? "Escalated"
                : item?.currentStatus === 2
                  ? "Need To Close"
                  : item?.currentStatus === 3
                    ? "Closed"
                    : "";
          item.to = item?.complaintTo?.name;
          item.ward = item?.ward?.ward_name;
          item.type =
            item?.complaintType === 0
              ? "Complaint"
              : item?.complaintType === 1
                ? "Request"
                : item?.complaintType === 2
                  ? "Interaction"
                  : "";
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                onClick={() => {
                  handleClickOpenModal(item._id);
                  setComplaintId(item._id);
                  setFollowUp((prev) => ({
                    ...prev,
                    complaintId: item._id,
                  }));
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginRight: "0.5em",
                }}
              ></i>
              <i
                onClick={() => {
                  setStaffComplaintIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
              ></i>
            </div>
          );
          return item;
        });
        setTableData(data);
      });
  };
  const handlePopUpTable = () => {
    axios
      .get(
        `${API_URL}complaint/followup/list?complaintId=${followUp?.complaintId}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        const data = res?.data?.data;
        data?.map((item, index) => {
          item.date = moment(item?.followupDate).format("DD-MM-YYYY");
          item.id = index + 1;
          item.status =
            item?.followupStatus === 0
              ? "Active"
              : item?.followupStatus === 1
                ? "Escalated"
                : item?.followupStatus === 3
                  ? "Closed"
                  : null;
          item.staff = `${item.followupAddedBy?.firstName || ""} ${item.followupAddedBy?.lastName || ""
            }`;
          const validImages =
            item.followupImg?.filter((img) => img.trim() !== "") || [];
          item.img =
            validImages.length > 0
              ? item.followupImg.map((el, index) => (
                <a
                  key={index}
                  href={`${API_URL}uploads/complaint_images/${el}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: "5px" }} // Optional styling for spacing
                >
                  <i
                    className="fas fa-file-image"
                    style={{ cursor: "pointer", fontSize: "18px" }} // Adjust size as needed
                  />
                </a>
              ))
              : null;

          item.audio = item?.followupAudio && (
            <audio controls style={{ height: "35px", width: "252px" }}>
              <source
                src={`${API_URL}uploads/complaintaudio/${item.followupAudio}`}
                target="_blank"
                type="audio/mpeg"
              />{" "}
              6r
              {/* <source
                              src={`https://nodeapi.nellikkastore.com/uploads/complaintaudio/20240902154453_sample-3s.mp3`}
                              type="audio/mpeg"
                            /> */}
              Your browser does not support the audio element.
            </audio>
          );
          return item;
        });
        setFollowUpTableData(data);
        console.log(res);
      });
  };
  const handleClickOpenModal = (id) => {
    axios
      .get(`${API_URL}complaint/view?complaintId=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        const data = res?.data?.data[0];
        setOpenModal(true);
        setPopupData(data);
      });
  };
  const fetch_all_groups = (selected) => {
    axios
      .get(
        `${API_URL}api/staff/groups?localbody_id=` +
        localbody +
        "&ward_id=" +
        selected.value,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.success === true) {
          var group_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.group_name,
                value: el._id,
              };
            });
        } else {
          group_data = [];
        }

        setGroupOptions([
          {
            options: group_data,
          },
        ]);
      });
  };
  const fetch_all_customers = (localbody, query) => {
    let wardId = master?.wardId;
    let data = {
      keyword: query,
      localbody_id: [localbody],
      wardId,
    };

    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        // setCustomerOptions([
        //   {
        //     options: customer_data,
        //   },
        // ]);
      });
  };
  const fetch_customers = () => {
    axios
      .get(`${API_URL}complaint/name/select?wardId=${master.wardId}&type=1`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data = res.data.data;

        setCustomerOptions(customer_data);
      });
  };
  const handleValueChange = (name, e) => {
    const mastr = { ...master };
    if (name === "complaintFrom") {
      mastr.complaintFrom = {
        ...mastr.complaintFrom,
        number: e,
      };
    } else if (name === "complaintTo") {
      mastr.complaintTo = {
        ...mastr.complaintTo,
        number: e,
      };
    } else {
      mastr[name] = e;
    }
    setMaster(mastr);
  };
  const handleReset = () => {
    formRef.current.reset();
    setMaster(initialState);
    setSelected({
      complaintType: "",
      wardId: "",
      category: "",
      against: "",
    });
  };
  const handleSelect = (name, e) => {
    const mastr = { ...master };
    const select = { ...selected };
    const value = e
      ? isArray(e)
        ? e.map((item) => item.value)
        : e.value
      : null;
    mastr[name] = value;
    select[name] = e;
    setMaster(mastr);
    if (name === "wardId") {
      fetch_all_groups(e);
    }
    setSelected(select);
  };
  const handlePopUpValue = (name, e) => {
    const followup = { ...followUp };
    followup[name] = e;
    setFollowUp(followup);
  };
  const handlePopUpSelect = (name, e) => {
    const followup = { ...followUp };
    const select = { ...selected };
    const value = e
      ? isArray(e)
        ? e.map((item) => item.value)
        : e.value
      : null;
    followup[name] = value;
    select[name] = e;
    setFollowUp(followup);

    setSelected(select);
  };
  const handleSelectchange = (name, e) => {
    if (name === "complaintFrom" || name === "complaintTo") {
      // const isExistingOption = e && e.hasOwnProperty("value");
      const isObjectId = checkObjectIdValid(e.value);
      setMaster((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          id: isObjectId ? e.value : null,
          name: e.label,
          number: e.mobile || null,
        },
      }));
    }
  };
  const handleFilterSelectValueChange = (name, selected) => {
    const obj = { ...filterSelectObject };
    obj[name] = selected;
    setFilterSelectObject(obj);
    handleFilterValueChange(name, selected.value);
  };
  const handleFilterValueChange = (name, value) => {
    const obj = { ...filterObject };
    obj[name] = value;
    setFilterObject(obj);
  };
  const deleteComplaintImage = (e, idx) => {
    e.preventDefault();
    const prevImage = [...master.image];
    prevImage[idx] = "";
    setMaster((prevState) => ({
      ...prevState,
      image: prevImage,
    }));

    // FollowUp
    const popUpprevImage = [...followUp.image];
    popUpprevImage[idx] = "";
    setFollowUp((prevState) => ({
      ...prevState,
      image: popUpprevImage,
    }));
  };
  const uploadImage = (e, idx) => {
    const fd = new FormData();
    fd.append("complaint_image", e.target.files[0]);
    axios
      .post(`${API_URL}customercomplaint/complaintImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.file[0];
          if (e.target.name === "image") {
            const prevImage = [...master.image];
            prevImage[idx] = result.filename;
            setMaster((prevState) => ({
              ...prevState,
              image: prevImage,
            }));
          } else {
            const popUpprevImage = [...followUp.image];
            popUpprevImage[idx] = result.filename;
            setFollowUp((prevState) => ({
              ...prevState,
              image: popUpprevImage,
            }));
          }
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file, "audio");
    if (file) {
      const fd = new FormData();
      fd.append("file", file);
      axios
        .post(`${API_URL}complaint/audio/`, fd, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setMaster((prev) => ({
              ...prev,
              audio: response.data.file.filename,
            }));

            setFollowUp((prev) => ({
              ...prev,
              audio: response.data.file.filename,
            }));
            console.log("success");
          } else {
            toastr.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
  };
  
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
      },
      {
        label: "Date",
        field: "data",
        sort: "asc",
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
      },
      {
        label: "ID",
        field: "uniqueId",
        sort: "asc",
      },
      {
        label: "From",
        field: "from",
        sort: "asc",
      },
      {
        label: "To",
        field: "to",
        sort: "asc",
      },
      // {
      //   label: "Category",
      //   field: "categoary",
      //   sort: "asc",
      //   width: "150",
      // },
      // {
      //   label: "Mobile",
      //   field: "Staff_mobile",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Localbody",
      //   field: "localbody",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Ward",
        field: "ward",
        sort: "asc",
      },
      // {
      //   label: "Group",
      //   field: "group",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Priority",
      //   field: "complaint_priority",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "status",
        sort: "asc",
      },
      // {
      //   label: "Staff",
      //   field: "staaff",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
      },
    ],
    rows: tableData,
  };
  const complaintTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Complaint ID",
      //   field: "complaint_id",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Audio",
        field: "audio",
        sort: "asc",
        width: 150,
      },
      {
        label: "Image",
        field: "img",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Assigned",
      //   field: "assigned",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Comment",
        field: "followupComment",
        sort: "asc",
        width: 150,
      },

      {
        label: "AddedBy",
        field: "staff",
        sort: "asc",
        width: 150,
      },
    ],
    rows: FollowUpTableData,
  };
  function handleSubmit() {
    if (master._id) {
      put(`/complaint/staff/update`, master)
        .then((res) => {
          toastr.success(res.message);
          handleTableData(localbody);
          handleReset();

          formRef.current.reset();
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          console.log(err, "errror");
        });
    } else {
      post(`/complaint/staff`, master)
        .then((res) => {
          toastr.success(res.message);
          handleTableData(localbody);
          handleReset();
          setSelected({
            complaintType: "",
            wardId: "",
            category: "",
            against: "",
          });
          formRef.current.reset();
        })
        .catch((err) => {
          // formRef.current.reset()
          toastr.error(err.response.data.message);
        });
    }
  }
  function handlePopUpSubmit(e) {
    e.preventDefault();
    if (followUp.status === 1) {
      put(`/complaint/assign`, followUp)
        .then((res) => {
          handlePopUpTable();
          handlePopupReset();
          handleClickOpenModal(complaintId)
          handleTableData(localbody);
          toastr.success(res?.message);
        })
        .catch((err) => {
          toastr.error(err?.data?.message);
          console.log(err, "errror");
        });
    } else {
      put(`/complaint/close`, followUp)
        .then((res) => {
          handlePopUpTable();
          handlePopupReset();
          handleClickOpenModal(complaintId)
          handleTableData(localbody);
          toastr.success(res?.message);
        })
        .catch((err) => {
          // formRef.current.reset()
          toastr.error(err?.data?.message);
        });
    }
  }
  const handlePopupReset = () => {
    setFollowUp({
      localbodyId: getLocalbody(),
      userId: getUserId(),
      complaintId: complaintId,
      image: [""],
      status: "",
      comment: "",
    });
    setSelected({
      status: "",
    });
  };
  const handleFilterReset = () => {
    setFilterObject({
      fromDate: "",
      toDate: "",
      wardId: "",
      status: "",
      complaintType: "",
      against: "",
    });
    setFilterSelectObject({
      wardId: "",
      status: "",
      complaintType: "",
      against: "",
    });
  };



  return (
    <div className="page-content">
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}complaint/delete?complaintId=` +
                staffComplaintIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                toastr.success(res?.data?.message);
                console.log(res, "delete");

                handleTableData(localbody);
              })
              .catch((err) => {
                toastr.error(err?.data?.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Complaint" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  onValidSubmit={handleSubmit}
                  ref={formRef}
                  className="needs-validation"
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="wardId"
                          options={wardOptions}
                          value={selected.wardId}
                          onChange={(selected) => {
                            handleSelect("wardId", selected);
                          }}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>From</Label>
                        <Select
                          name="from"
                          options={[{ label: "Customer", value: "1" }, { label: "Staff", value: "2" }]}
                          value={selected.wardId}
                          onChange={(selected) => {
                            handleSelect("from", selected);
                          }}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {selected?.from?.value == "1" ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Customer</Label>
                            <Select
                              name="customer"
                              options={[{ label: "Shammas", value: "1" }, { label: "Akshay", value: "2" }]}
                              value={selected.wardId}
                              onChange={(selected) => {
                                handleSelect("customer", selected);
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    {selected?.from?.value == "2" ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Staff</Label>
                            <Select
                              name="staff"
                              options={[{ label: "Shammas", value: "1" }, { label: "Akshay", value: "2" }]}
                              value={selected.wardId}
                              onChange={(selected) => {
                                handleSelect("staff", selected);
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    {selected?.from ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Mobile</Label>
                            <AvField name="mobile" placeholder="Mobile" />
                          </div>
                        </Col>
                      </>
                    ) : null}

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Type</Label>
                        <Select
                          name="type"
                          options={[{ label: "Compliant", value: "1" }, { label: "Request", value: "2" }, { label: "Interaction", value: "3" }]}
                          value={selected.type}
                          onChange={(selected) => {
                            handleSelect("type", selected);
                          }}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {selected?.type?.value == "1" ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Complaint Category</Label>
                            <Select
                              name="category"
                              options={[{ label: "Misbehaviour", value: "1" }, { label: "No Response", value: "2" }, { label: "Unhygenic", value: "3" }]}
                              // value={selected.type}
                              onChange={(selected) => {
                                handleSelect("category", selected);
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                      </>
                    ) : null}
                    {selected?.type?.value == "1" ? (
                      <>
                        <h6>Complaint againt {selected?.from == 1 ? "Customer " : "Staff "}Details</h6>

                        {selected?.from?.value == "1" ? (
                          <>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Staff</Label>
                                <Select
                                  name="staff"
                                  options={[{ label: "Shammas", value: "1" }, { label: "Akshay", value: "2" }]}
                                  // value={selected.wardId}
                                  onChange={(selected) => {
                                    handleSelect("staff", selected);
                                  }}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                            </Col>
                          </>
                        ) : null}

                        {selected?.from?.value == "2" ? (
                          <>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Customer</Label>
                                <Select
                                  name="staff"
                                  options={[{ label: "Shammas", value: "1" }, { label: "Akshay", value: "2" }]}
                                  // value={selected.wardId}
                                  onChange={(selected) => {
                                    handleSelect("staff", selected);
                                  }}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                            </Col>
                          </>
                        ) : null}

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Mobile</Label>
                            <AvField
                              name="staff"
                              placeholder="Mobile"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>TAT</Label>
                            <AvField
                              name="TAT"
                              placeholder="TAT"
                            />
                          </div>
                        </Col>

                      </>
                    ) : null}

                    {selected?.type?.value == "2" || selected?.type?.value == "3" ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Comments</Label>
                            <AvField
                              name="complaint_audio"
                              type="textarea"
                              className="form-control"
                              placeholder="Comments"
                              rows={1}
                              id="validationCustom03"
                            // onChange={handleFileChange}
                            // accept="audio/*" // This restricts the file selection to audio files
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}




                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom03">Upload Audio</Label>
                        <AvField
                          name="complaint_audio"
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                          onChange={handleFileChange}
                          accept="audio/*" // This restricts the file selection to audio files
                        />
                      </div>
                    </Col>

                    {master?.image.map((item, idx) => (
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Image</Label>
                          {item ? (
                            <div div className="img-wraps">
                              <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${API_URL}uploads/complaint_images/${item}`}
                              />

                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                type="button"
                                onClick={(e) => deleteComplaintImage(e, idx)}
                                style={{ width: "150px" }}
                              // type="reset"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={(e) => uploadImage(e, idx)}
                              rows="1"
                            />
                          )}
                        </div>

                        <div className="d-flex justify-content-end align-items-center">
                          {master?.image?.length - 1 === idx ? (
                            <span
                              onClick={() => {
                                const data = { ...master };
                                data.image.push("");
                                setMaster(data);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Add more image{" "}
                              <i className="mdi mdi-plus-circle-outline"></i>
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                const data = { ...master };
                                data.image.splice(idx, 1);
                                setMaster(data);
                              }}
                              style={{
                                fontSize: "1.125rem",
                                cursor: "pointer",
                              }}
                            >
                              <i className="trash-btn mdi mdi-delete"></i>
                            </span>
                          )}
                        </div>
                      </Col>
                    ))}
                    <Col>
                      <div className="mb-3" style={{ paddingTop: "25px" }}>
                        <Button
                          className="me-2"
                          color={master._id ? "warning" : "primary"}
                          type="submit"
                        >
                          {master._id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          onClick={handleReset}
                          color="danger"
                          type="button"
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <AvForm>
            <Row>
              <Col md={2} className="mb-3">
                <Label>From</Label>
                <AvField
                  name="fromDate"
                  type="date"
                  onChange={(e) =>
                    handleFilterValueChange("fromDate", e.target.value)
                  }
                  value={filterObject.fromDate}
                />
              </Col>
              <Col md={2} className="mb-3">
                <Label>To </Label>
                <AvField
                  name="toDate"
                  type="date"
                  onChange={(e) =>
                    handleFilterValueChange("toDate", e.target.value)
                  }
                  value={filterObject.toDate}
                />
              </Col>
              <Col md="2">
                <div className="mb-3">
                  <Label htmlFor="validationCustom01">Type</Label>
                  <Select
                    name="complaintType"
                    value={filterSelectObject?.complaintType}
                    onChange={(selected) => {
                      handleFilterSelectValueChange("complaintType", selected);
                    }}
                    options={[
                      {
                        label: "Complaint",
                        value: 0,
                      },
                      {
                        label: "Request",
                        value: 1,
                      },
                      {
                        label: "Interaction",
                        value: 2,
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="mb-3">
                  <Label>Ward</Label>
                  <Select
                    name="wardId"
                    options={wardOptions}
                    value={filterSelectObject?.wardId}
                    onChange={(selected) => {
                      handleFilterSelectValueChange("wardId", selected);
                    }}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="mb-3">
                  <Label>Status</Label>
                  <Select
                    name="status"
                    value={filterSelectObject?.status}
                    onChange={(selected) => {
                      handleFilterSelectValueChange("status", selected);
                    }}
                    options={[
                      {
                        label: "Active",
                        value: 0,
                      },
                      {
                        label: "Escalated",
                        value: 1,
                      },
                      {
                        label: "Need To Close",
                        value: 2,
                      },
                      {
                        label: "Closed",
                        value: 3,
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="mb-3">
                  <Label>Against</Label>
                  <Select
                    name="against"
                    value={filterSelectObject?.against}
                    onChange={(selected) => {
                      handleFilterSelectValueChange("against", selected);
                    }}
                    options={[
                      {
                        label: "Staff",
                        value: 1,
                      },
                      {
                        label: "Customer",
                        value: 2,
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
              <Col className="mb-3" md={2} style={{ marginTop: "10px" }}>
                <Button
                  color="danger"
                  onClick={handleFilterReset}
                  type="button"
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </AvForm>
          <MDBDataTable
            responsive
            bordered
            id="complainstId"
            data={data}
            sortable={false}
            searching={false}
          />
        </Row>
      </div>
      <Modal
        show={openModal}
        toggle={handleCloseModal}
        size="xl"
        centered={true}
      >
        <div className="modal-header">
          {/* <h5 className="modal-title mt-0">Complaint </h5> */}
          <button
            type="button"
            onClick={() => {
              setOpenModal(false);
              setFollowUp(followupState);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        {/* <AvForm className="needs-validation"> */}
        <div className="modal-body">
          <div class="row">
            <div class="col-md-12">
              <ul className="lead-details">
                <li>
                  Date :
                  <label class="form-label">
                    {" "}
                    {moment(popupData?.date).format("DD-MM-YYYY")}
                  </label>
                </li>
                <li>
                  Ward :<label class="form-label"> {popupData.ward}</label>
                </li>
                <li>
                  Type :
                  <label class="form-label">
                    {" "}
                    {popupData?.complaintType === 0
                      ? "Complaint"
                      : popupData?.complaintType === 1
                        ? "Request"
                        : popupData?.complaintType === 2
                          ? "Interaction"
                          : ""}
                  </label>
                </li>
                {popupData?.category && (
                  <li>
                    Complaint Category :
                    <label class="form-label"> {popupData?.category}</label>
                  </li>
                )}
                {popupData?.Category && (
                  <li>
                    Category :
                    <label class="form-label">{popupData?.Category}</label>
                  </li>
                )}
                <li>
                  From :
                  <label class="form-label">
                    {popupData?.complaintFrom?.name}
                  </label>
                </li>
                <li>
                  Mobile :
                  <label class="form-label">
                    {" "}
                    {popupData?.complaintFrom?.number}
                  </label>
                </li>
                <li>
                  To :
                  <label class="form-label">
                    {popupData?.complaintTo?.name}
                  </label>
                </li>
                <li>
                  Mobile :
                  <label class="form-label">
                    {" "}
                    {popupData?.complaintTo?.number}
                  </label>
                </li>
                <li>
                  TAT :<label class="form-label"> {popupData?.tatTime}</label>
                </li>
                <li>
                  Comments :
                  <label class="form-label"> {popupData?.discription}</label>
                </li>
                <li>
                  Current Status :
                  <label class="form-label">
                    {" "}
                    {popupData?.currentStatus === 0
                      ? "Active"
                      : popupData?.currentStatus === 1
                        ? "Escalated"
                        : popupData?.currentStatus === 2
                          ? "Need To Close"
                          : popupData?.currentStatus === 3
                            ? "Closed"
                            : ""}
                  </label>
                </li>
                <li>
                  Assigned To :
                  <label class="form-label"> {popupData?.assign}</label>
                </li>
                {popupData?.closed &&
                  Object.keys(popupData?.closed).length > 0 && (
                    <>
                      <li>
                        Closed By:
                        <label className="form-label">
                          {" "}
                          {popupData?.closed?.by}
                        </label>
                      </li>
                      <li>
                        Closed Date & Time:
                        <label className="form-label">
                          {`${moment(popupData?.closed?.date).format(
                            "DD-MM-YYYY"
                          )} ${moment(popupData?.closed?.time, "HHmmss").format(
                            "hh:mm a"
                          )}`}
                        </label>
                      </li>
                    </>
                  )}
              </ul>
            </div>
            <div class="col-md-12">
              <ul className="lead-details">
                {popupData?.image?.filter((item) => item).length > 0 && (
                  <li>
                    Image :
                    <label className="form-label">
                      {popupData?.image
                        ?.filter((item) => item)
                        .map((item, index) => (
                          <img
                            key={index}
                            className="me-2"
                            style={{ width: "100px", height: "100px" }}
                            src={`${API_URL}uploads/complaint_images/${item}`}
                            alt={`Image ${index}`}
                          />
                        ))}
                    </label>
                  </li>
                )}
                {popupData?.audio && (
                  <li>
                    Audio :
                    <label class="form-label">
                      <audio controls style={{ height: "35px" }}>
                        <source
                          src={`${API_URL}uploads/complaintaudio/${popupData.audio}`}
                          target="_blank"
                          type="audio/mpeg"
                        />
                        {/* <source
                              src={`https://nodeapi.nellikkastore.com/uploads/complaintaudio/20240902154453_sample-3s.mp3`}
                              type="audio/mpeg"
                            /> */}
                        Your browser does not support the audio element.
                      </audio>
                    </label>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <Row>
          <Col className="col-12">
            {!(popupData?.currentStatus === 3) && (
              <AvForm ref={PopUpRef} onValidSubmit={handlePopUpSubmit}>
                <Row style={{ paddingLeft: "15px" }}>
                  <h5>Followup</h5>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Status</Label>
                      <Select
                        name="status"
                        value={selected.status}
                        onChange={(selected) => {
                          handlePopUpSelect("status", selected);
                        }}
                        options={[
                          // {
                          //   label: "Active",
                          //   value: 0,
                          // },
                          {
                            label: "Escalated",
                            value: 1,
                          },
                          {
                            label: "Closed",
                            value: 3,
                          },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                      <p
                        className="text-danger"
                        style={{ fontSize: "11px" }}
                      ></p>
                    </div>
                  </Col>

                  {followUp.status === 1 && (
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Assign Staff</Label>
                        <Select
                          name="assignId"
                          options={assaignedStaff}
                          value={selected.assignId}
                          onChange={(selected) => {
                            handlePopUpSelect("assignId", selected);
                          }}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                  )}

                  {followUp.status === 1 ? null : (
                    <>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Upload Audio
                          </Label>
                          <AvField
                            name="complaint_audio"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleFileChange}
                            accept="audio/*" // This restricts the file selection to audio files
                          />
                        </div>
                      </Col>
                      {followUp?.image.map((item, idx) => (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Image</Label>
                            {item ? (
                              <div div className="img-wraps">
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/complaint_images/${item}`}
                                />

                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  type="button"
                                  onClick={(e) => deleteComplaintImage(e, idx)}
                                  style={{ width: "150px" }}
                                // type="reset"
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="popupimage"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={(e) => uploadImage(e, idx)}
                                rows="1"
                              />
                            )}
                          </div>

                          <div className="d-flex justify-content-end align-items-center">
                            {followUp?.image?.length - 1 === idx ? (
                              <span
                                onClick={() => {
                                  const data = { ...followUp };
                                  data.image.push("");
                                  setFollowUp(data);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Add more image{" "}
                                <i className="mdi mdi-plus-circle-outline"></i>
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  const data = { ...followUp };
                                  data.image.splice(idx, 1);
                                  setFollowUp(data);
                                }}
                                style={{
                                  fontSize: "1.125rem",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="trash-btn mdi mdi-delete"></i>
                              </span>
                            )}
                          </div>
                        </Col>
                      ))}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Comments</Label>
                          <AvField
                            name="comment"
                            placeholder="Comments"
                            type="textarea"
                            value={followUp.comment}
                            onChange={(e) => {
                              handlePopUpValue(e.target.name, e.target.value);
                            }}
                            rows={1}
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  <Col md="3" style={{ paddingTop: "25px" }}>
                    <Button color="primary" className="me-2" type="submit">
                      Submit
                    </Button>
                    <Button
                      onClick={handlePopupReset}
                      color="danger"
                      className="me-2"
                      type="reset"
                    >
                      {"Reset"}
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            )}

            <Row
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
              className="mt-2"
            >
              <MDBDataTable
                id="complaintPopupTableDataId"
                responsive
                bordered
                data={complaintTableData}
                paging={false}
                searching={false}
              />
            </Row>
          </Col>
        </Row>
        {/* </AvForm> */}
      </Modal>
    </div>
  );
};

export default Complaints;
