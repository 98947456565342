import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  // Modal,
  // Table,
} from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
  getArraySumByKey,
  formatMoney,
  isSuperAdmin,
  getUserId
} from "../../../helpers/globalFunctions";
import "./customercollectstatus.scss";
import { getCustomerType } from "../../../store/actions";
import { jsonToExcel } from "../../../helpers/api_helper";
import { Pagination } from "@mui/material"
import toastr from "toastr";

const DaywiseReport = (props) => {
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [date, setDate] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [details, setDetails] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [customerType, setCustomerType] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [total, setTotal] = useState(0);
  const [data, setData] = useState({});
  const [tabledata, settabledata] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(20)
  const [disblebutton, setdisblebutton] = useState(false)

  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);
  useEffect(() => {
    handleTableData();
    fetch_all_staff(currentLocalbody);
    fetch_all_wards(currentLocalbody);
    // dispatch(getCustomerType());
  }, [currentLocalbody, searchData, page,date]);

  useEffect(() => {
    if (selectedCategory != null) {
      dispatch(getCustomerType(selectedCategory.value))
    }
  }, [selectedCategory]);

  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);

  const handlePageChange = (e, value) => {
    setPage(value)
  }
  useEffect(() => {
    const updatedColumns = generateColumns(searchData?.from_date, searchData?.to_date);
    setData({ columns: updatedColumns });
  }, [searchData]);


  function handleExportData() {
    setdisblebutton(true)
    axios.post(`${API_URL}api/staff/customer/collect/report?localbodyId=${currentLocalbody}&userId=${getUserId()}&fromDate=${searchData?.from_date}&toDate=${searchData?.to_date}&wardId=${searchData?.ward_id}&groupId=${searchData?.group_id}&category=${searchData?.category_id}&dates=${date}`, {}, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      let result = res.data.data
      let exportData = []

      let dateStart = moment(searchData?.from_date);
      let dateEnd = moment(searchData?.to_date);
      let dates = [];

      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        let formattedDate = dateStart.format('MMM YY'); // Format the date
        dates.push(formattedDate); // Add to dates array

        dateStart.add(1, 'month'); // Increment by 1 month
      }

      result && result.map((item, index) => {
        let exportItem = {};
        item.id = index + 1;
        item.cust_date = moment(item.cust_date).format("DD-MM-YYYY");
        exportItem["Sl.No"] = item.id;
        exportItem.Date = item.cust_date;
        exportItem.Name = item.cust_name;
        exportItem.Reg_No = item.cust_reg_no;
        exportItem.Ward = item.wardName;
        exportItem.Group = item.groupName;
        exportItem.CustomerType = item.cust_type;
        exportItem.Building_No = item.cust_house_num;
        exportItem.Due_Amount = item.cust_due;
        Object.assign(exportItem, item.months)

        exportData.push(exportItem)

      })

      if (exportData.length > 0) {
        jsonToExcel(exportData, `report-${moment().format("DD-MM-YY")}`)
      } else {
        toastr.warning("There are no data to export")
      }

    }).catch((err) => {
      console.log(err)
    }).finally((res) => {

      setdisblebutton(false)
    })
  }

  const generateColumns = (from_date, to_date) => {
    let dateStart = moment(from_date);
    let dateEnd = moment(to_date);
    let dates = [];
    let apiDates = []
    let columns = [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "cust_date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "cust_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "cust_reg_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "cust_phone",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ward",
        field: "wardName",
        sort: "asc",
        width: 150,
      },
      {
        label: "House",
        field: "cust_house_num",
        sort: "asc",
        width: 150,
      },
      {
        label: "Group",
        field: "groupName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Package",
        field: "packageName",
        sort: "asc",
        width: 150,
      }
    ];
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      let formattedDate = dateStart.format('MMM YY'); 
      dates.push(formattedDate); 
      apiDates.push(dateStart.format('YYYY-MM'))
     
      columns.push({
        label: formattedDate,
        field:dateStart.format("YYYY-MM"),
        // field: "m" + (dates.length),  // Assign dynamic field name m1, m2, m3...
        sort: "asc",
        width: 150,
      });
      dateStart.add(1, 'month'); // Increment by 1 month
    }
    setDate(apiDates)
    return columns;
  };


 
  function handleTableData() {
    axios.post(`${API_URL}api/staff/customer/collect/report?localbodyId=${currentLocalbody}&userId=${getUserId()}&fromDate=${searchData?.from_date}&toDate=${searchData?.to_date}&wardId=${searchData?.ward_id}&groupId=${searchData?.group_id}&limit=20&start=${page}&category=${searchData?.category_id}&dates=${date}`, {}, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      let result = res.data.data
      setCount(res.data.count)
      result && result.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        item.cust_date = item?.cust_date ? moment(item?.cust_date).format("DD-MM-YYYY") : "--"
        Object.assign(item, item.months)
        return item
      })
      settabledata(result)
    })
  }

  const datas = {
    columns: data.columns,
    rows: tabledata
  };


  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };


  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSelectedGroup(null);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
          ["group_id"]: "",
        });
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
            currentLocalbody +
            "&ward_id=" +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });

        break;
      case "group":
        setSelectedGroup(selected);

        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });

        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });

        break;
      case "category_type":
        setSelectedCategory(selected);
        setSelectedCustomerType(null);
        setSearchData({
          ...searchData,
          ["category_type"]: selected.value,
          ["category_id"]: "",
        });
        break;
      case "customerType":
        setSelectedCustomerType(selected);
        setSearchData({
          ...searchData,
          category_id: selected.value,
        });

        break;
      default:
        break;
    }
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    setSelectedCategory(null);
    setSelectedCustomerType(null);
    handleTableData();
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let category_id = searchData?.category_id ? searchData.category_id : "";
    let category_type = searchData?.category_type ? searchData.category_type : "";
    handleTableData(date1, date2, ward_id, group_id, staff_id, category_id, category_type);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Customer Collect Report" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          name="group_id"
                          value={selectedGroup}
                          onChange={(selected) => {
                            handleSelectChange(selected, "group");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>


                    <Col md="2">
                      <div className="mb-3">
                        <Label>Category Type</Label>
                        <Select
                          name="category_type"
                          value={selectedCategory}
                          classNamePrefix='select2-selection'
                          options={[
                            { label: "Residential", value: 1 },
                            { label: 'Commercial', value: 2 }
                          ]}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category_type")
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Customer Type</Label>
                        <Select
                          name="category_id"
                          value={selectedCustomerType}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customerType");
                          }}
                          options={customerType}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="3" >
                      <div style={{ display: "flex" }}>
                        {/* <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "22px" }}>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </Button>
                        </div> */}
                        {isSuperAdmin() ? (
                          <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "22px" }}>
                            <Button onClick={handleExportData} color="success" type="submit" disabled={disblebutton}>
                              {disblebutton ? "Loading...." : "Export"}
                            
                            </Button>
                          </div>
                        ) : null}
                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "22px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>

                  </Row>
                  <MDBDataTable
                    id="customercollectReportTableId"
                    data={datas}
                    sortable={false}
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    bordered
                    responsive
                    entries={20}
                  />
                  <div class="row align-items-center">
                    <div
                      dataTest="datatable-info"
                      className="col-sm-12 col-md-5"
                    >
                      <div
                        className="dataTables_info"
                        role="status"
                        ariaLive="polite"
                      >
                        Showing {(page - 1) * limit + 1} to{" "}
                        {Math.min(page * limit, count)} of {count} entries
                      </div>
                    </div>
                    <div
                      data-test="datatable-pagination"
                      className="col-sm-12 col-md-7"
                    >
                      <div className="dataTables_paginate">
                        <Pagination
                          page={page}
                          count={Math.ceil(count / limit)}
                          shape="rounded"
                          size="small"
                          onChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DaywiseReport;
