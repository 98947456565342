
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import LinearProgress from "@mui/material/LinearProgress";
import $ from "jquery";
import moment from "moment";
import {
  // deleteUser,
  apiError,
  // getCustomers,
  getCustomerType,
  // addCustomers,
  // deleteCustomers,
  // updateCustomers,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLocalbody, getLocalbodyName, getDate } from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { CSVLink } from "react-csv";
import "./AddCustomer.scss";
import Pagination from "@mui/material/Pagination";
import Loader from "react-spinner-loader";

const Customers = (props) => {
  const [customerIdTobeUpdated, setCustomerIdTobeUpdated] = useState(null);
  const [customerIdToBeDeleted, setCustomerIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [details, setDetails] = useState([]);
  const [tableData, setTableData] = useState({});
  const [master, setMaster] = useState({
    localbody_name: getLocalbody(),
  });
  const [masterObj, setMasterObj] = useState({
    localbody_name: getLocalbody(),
  });
  const [popupView, setPopupView] = useState(false);
  const [customerType, setCustomerType] = useState([]);
  const [custType, setCustType] = useState([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [selectedVisitday, setSelectedVisitday] = useState(null);
  const [showVisit, setShowVisit] = useState(false);
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [packageOptions, setPackageOptions] = useState([]);
  const [packageSearchOptions, setPackageSearchOptions] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedBillingtype, setSelectedBillingtype] = useState(null);
  const [selectedWardSearch, setSelectedWardSearch] = useState(null);
  const [searchGroupOptions, setSearchGroupOptions] = useState([]);
  const [selectedGroupSearch, setSelectedGroupSearch] = useState(null);
  const [selectedCustomerTypeSearch, setSelectedCustomerTypeSearch] = useState(null);
  const [selectedPackageSearch, setSelectedPackageSearch] = useState(null);
  const [selectedCategorySearch, setSelectedCategorySearch] = useState(null);
  const [searchData, setSearchData] = useState({
    // from_date: getFirstday(new Date()),
    // to_date: getDate(new Date()),
  });
  const [uploadProgress, setUploadProgress] = useState();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectednonStatus, setnonSelectedStatus] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [showExport, setshowExport] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [typeTimeout, setTypeTimeout] = useState(0);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [selectedFlatname, setSelectedFlatName] = useState(null);
  const [flatOptions, setFlatOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [keyWord, setKeyWord] = useState("");
  const [BuildingNo, setBuildingNo] = useState("");
  const [Building, setBuilding] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterData1, setFilterData1] = useState([]);

  const [value, setValue] = useState(0);

  const {
    // customers,
    customerTypes,
    addingCustomer,
    // addCustomerResponse,
    // updateCustomerResponse,
    // deleteCustomerResponse,
    // error,
  } = useSelector((state) => state.customers);

  const [errors, setErrors] = useState({
    billingtypeError: "",
    groupError: "",
    packageError: "",
    wardError: "",
    districtError: "",
    customertypeError: "",
    designationError: "",
    categoryError: "",
  });

  const dispatch = useDispatch();
  const formRef = useRef();
  let history = useHistory();

  const ward1 = selectedWard?.value;

  /** pagination */
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(currentPage * PerPageCount);

  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(indexOfLastTransfer - PerPageCount);
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);

    handleTableData(
      searchValue,
      searchData?.from_date,
      searchData?.to_date,
      searchData?.ward_id,
      searchData?.group_id,
      searchData?.cust_type_id,
      searchData?.package_id,
      masterObj?.house_num,
      searchData?.status_id === 1 ? 1 : searchData?.status_id === 0 ? 0 : "",
      searchData?.noncorporative_status !== null ? searchData.noncorporative_status : "",
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  };
  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => func(...args), delay);
    };
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (value !== 0) {
        handleSearch(value);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [value]);

  const handleSearch = (value) => {
    setSearchLoader(true);
    setSearchValue(value);
    resetPaginate();
    handleTableData(
      value,
      searchData?.from_date,
      searchData?.to_date,
      searchData?.ward_id,
      searchData?.group_id,
      searchData?.cust_type_id,
      searchData?.package_id,
      masterObj?.house_num,
      searchData?.status_id === 1 ? 1 : searchData?.status_id === 0 ? 0 : "",
      searchData?.noncorporative_status !== null ? searchData.noncorporative_status : ""
    );
  };

  useEffect(() => {
    if (details.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(
        searchValue,
        searchData?.from_date,
        searchData?.to_date,
        searchData?.ward_id,
        searchData?.group_id,
        searchData?.cust_type_id,
        searchData?.package_id,
        masterObj?.house_num,
        searchData?.status_id === 1 ? 1 : searchData?.status_id === 0 ? 0 : "",
        searchData?.noncorporative_status !== null ? searchData.noncorporative_status : ""
      );
    }
  }, [details]);

  /** end pagination */
  useEffect(() => {
    //dispatch(getCustomers());
    handleTableData(searchValue);
    // dispatch(getCustomerType());
    fetch_all_packages(currentLocalbody);
    fetch_all_designation();
    fetch_flat_name();
    fetch_all_wards(currentLocalbody);
    fetch_all_district(currentLocalbody);
    //fetch_export_data();
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      var userId = obj._id;
      if (userId == "621dbe998ba1f6a55cbbed2e") {
        setshowExport(true);
      }
    }
  }, [currentLocalbody]);

  useEffect(() => {
    if (selectedCategory != null) {
      dispatch(getCustomerType(selectedCategory.value));
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategorySearch != null) {
      dispatch(getCustomerType(selectedCategorySearch.value));
    }
  }, [selectedCategorySearch]);


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };


  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
          type: el.type,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);

  // useEffect(() => {
  //   if (addCustomerResponse.type === "success") {
  //     toastr.success(addCustomerResponse.message);
  //     handleTableData();
  //   } else if (addCustomerResponse.type === "failure") {
  //     toastr.error(addCustomerResponse.message);
  //   }
  // }, [addCustomerResponse]);

  // useEffect(() => {
  //   if (deleteCustomerResponse.type === "success") {
  //     toastr.success(deleteCustomerResponse.message);
  //     setCustomerIdToBeDeleted(null);
  //     handleTableData();
  //   } else if (deleteCustomerResponse.type === "failure") {
  //     toastr.error(error.data.message, deleteCustomerResponse.message);
  //   }
  // }, [deleteCustomerResponse]);

  // useEffect(() => {
  //   if (updateCustomerResponse.type === "success") {
  //     toastr.success(updateCustomerResponse.message);
  //     setCustomerIdTobeUpdated(null);
  //     handleTableData();
  //   } else if (updateCustomerResponse.type === "failure") {
  //     toastr.error(error.data.message, updateCustomerResponse.message);
  //   }
  // }, [updateCustomerResponse]);

  // const handleClickOpenForm = (item) => {
  //   setTableData(item);
  //   setPopupView(true);
  // };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  function fetch_all_packages(localbody_id, type = null, from = "") {
    axios
      .get(`${API_URL}api/staff/packages?cust_type=` + type + `&localbody_id=` + localbody_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          var package_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.package_name,
                value: el._id,
                status: el.visit_status,
              };
            });
        } else package_data = [];
        if (from == "")
          setPackageOptions([
            {
              options: package_data,
            },
          ]);
        else {
          setPackageSearchOptions([
            {
              options: package_data,
            },
          ]);
        }
      });
  }

  function fetch_flat_name() {
    axios
      .get(`${API_URL}api/staff/localbody/flatlist?localbody=${currentLocalbody}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var flat_data =
          res.data.data &&
          res.data.data.map((el, indx) => {
            if (selectedFlatname?.value) {
              setSelectedWard({ label: el.ward.ward_name, value: el.ward._id });
            }

            return {
              label: el.flat_name,
              value: el._id,
            };
          });
        setFlatOptions([{ options: flat_data }]);
      });
  }

  function handleSelectedPackage(value) {
    if (value.status == 1) {
      setShowVisit(true);
    } else {
      setShowVisit(false);
    }
    setSelectedPackage(value);
    setMaster({
      ...master,
      [`cust_package_id`]: value.value,
    });
    //setUserObject({ ...userObject, ["privilage"]: value.value });
  }
  // function fetch_all_packagesSearch(cust_type, localbody_id) {
  //   axios
  //     .get(
  //       `${API_URL}api/staff/packages?cust_type=` +
  //         cust_type +
  //         "&localbody_id=" +
  //         localbody_id,
  //       {
  //         headers: {
  //           "x-access-token": accessToken,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       var package_data =
  //         res.data.data &&
  //         res.data.data.map((el) => {
  //           return {
  //             label: el.package_name,
  //             value: el._id,
  //           };
  //         });

  //       setPackageSearchOptions([
  //         {
  //           options: package_data,
  //         },
  //       ]);
  //     });
  // }

  function fetch_all_district(localbody) {
    axios
      .get(`${API_URL}api/staff/districts?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var district_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.district_name,
                value: el._id,
              };
            });
        } else {
          district_data = [];
        }

        setDistrictOptions([
          {
            options: district_data,
          },
        ]);
      });
  }

  function fetch_all_designation() {
    axios
      .get(`${API_URL}designations/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var designation_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.desig_name,
              value: el._id,
            };
          });

        setDesignationOptions([
          {
            options: designation_data,
          },
        ]);
      });
  }
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    // handleTableData(date1, date2, ward_id, group_id, staff_id);
  };
  function handleTableData(
    search = "",
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    cust_type_id = "",
    package_id = "",
    house_num = "",
    status_id = "",
    noncorporative_status = "",
    start = 0,
    limit = 20
  ) {
    //setDetails([]);
    var url = `${API_URL}customers/list?localbody_id=` + currentLocalbody;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : "";
      to_date = to_date ? to_date : "";
    }
    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&cust_type_id=" +
      cust_type_id +
      "&package_id=" +
      package_id +
      "&house_num=" +
      house_num +
      "&verification_status=" +
      status_id +
      "&noncorporative_status=" +
      noncorporative_status +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      limit;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        setTotalPages(res.data.limit);
        // let reportData = [];
        var id = start;
        result.map((item, index) => {
          id++;
          item.id = id;
          item.localbody_name = getLocalbody();
          item.type = item?.cust_type_name;
          item.district_name = item?.district_name;
          item.ward_name = item?.ward_name;

          if (item.cust_verification_status === 1) {
            item.status = (
              <h6
                style={{
                  color: "green",
                }}>
                {"Verified"}
              </h6>
            );
            // item.status = (
            //   // <i
            //   //   className="uil-check"
            //   //   style={{
            //   //     fontSize: "1.8em",
            //   //     cursor: "pointer",
            //   //     marginLeft: "0.2em",
            //   //     marginRight: "0.5em",
            //   //     color:"green"
            //   //   }}
            //   // ></i>
            //   <div
            //     class="btn-group btn-group-sm"
            //     role="group"
            //     aria-label="Basic example"
            //   >
            //     <button
            //       class="btn btn-success"
            //       style={{ padding: "1px", minWidth: "50px" }} //63.36px
            //     >
            //       Verified
            //     </button>
            //   </div>
            // );
          } else {
            item.status = (
              <h6
                style={{
                  color: "red",
                }}>
                {"Not Verified"}
              </h6>

              //   <i
              //   className="uil-check"
              //   style={{
              //     fontSize: "1.8em",
              //     cursor: "pointer",
              //     // marginLeft: "0.2em",
              //     // marginRight: "0.5em",
              //     color:"red"
              //   }}
              // ></i>
              // <div
              //   class="btn-group btn-group-sm"
              //   role="group"
              //   aria-label="Basic example"
              // >
              //   <button
              //     class="btn btn-danger"
              //     style={{ padding: "1px", minWidth: "50px" }}
              //   >
              //     Not Verified
              //   </button>
              // </div>
            );
          }
          item.image = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={`${API_URL}uploads/customers_images/${item.cust_image}`} height="50" width="50" />
            </div>
          );
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  window.open("/Customer/" + item._id);
                }}></i>
              <i
                className="fas fa-check"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                  display: item?.cust_verification_status == 1 ? "none" : "block",
                }}
                title="Mark as Verified"
                onClick={() => {
                  verifyCustomer(item?._id, item?.cust_phone);
                }}></i>
              <i
                className="far fa-window-close"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                  display: item?.cust_verification_status != 1 ? "none" : "block",
                }}
                title="Mark as Unverified"
                onClick={() => {
                  UnverifyCustomer(item?._id, item?.cust_phone);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateCustomer(item);
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setCustomerIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </div>
          );

          // Rendering Status Button based on cust_status
          if (item.cust_status === 0) {
            item.status1 = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="success"
                  // type="sm"
                  style={{ fontSize: "smaller", padding: "3px 36px" }}
                  onClick={() => {
                    updateStatus(item);
                  }}>
                  Active
                </Button>
              </div>
            );
          } else if (item.cust_status === 2) {
            item.status1 = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => {
                    updateStatus(item);
                  }}>
                  Non co-operative
                </Button>
              </div>
            );
          } else {
            // Handle other cust_status values as needed
            item.status1 = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    updateStatus(item);
                  }}>
                  Permanent Lock
                </Button>
              </div>
            );
          }
        });
        setSearchLoader(false);
        setDetails(result);
        fetch_export_data(search, from_date, to_date, ward_id, group_id, cust_type_id, package_id, house_num, status_id, noncorporative_status);
      });
  }

  function updateStatus(item) {
    let data = {
      cust_id: item._id,
      status: item.cust_status,
    };

    axios
      .post(`${API_URL}api/staff/customer/status/change`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleTableData();
        }
      });
  }

  const fetch_export_data = (
    search = "",
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    cust_type_id = "",
    package_id = "",
    house_num = "",
    status_id = "",
    noncorporative_status = ""
  ) => {
    setExportLoader(true);
    var url = `${API_URL}customers/export/list?localbody_id=` + currentLocalbody;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : "";
      to_date = to_date ? to_date : "";
    }
    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&cust_type_id=" +
      cust_type_id +
      "&package_id=" +
      package_id +
      "&house_num=" +
      house_num +
      "&verification_status=" +
      status_id +
      "&noncorporative_status=" +
      noncorporative_status +
      "&search=" +
      search;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let dataToBeExported = [];
        let result = res.data.data;
        result &&
          result.map((el, index) => {
            let exportItem = {};
            exportItem.id = index + 1;
            //exportItem.Created_Date = moment(el.cust_date).format("DD-MM-YYYY");
            exportItem.Created_Date = moment(el.cust_date).format("DD-MM-YYYY");
            exportItem.Reg_No = el.cust_reg_no;
            exportItem.Name = el.cust_name;
            exportItem.Phone = el.cust_phone;
            exportItem.Whatsapp_No = el.cust_whatsapp_no;
            exportItem.Building_No = el.cust_house_num;
            exportItem.Customer_Type = el.cust_type_name;
            exportItem.Address1 = el.cust_address;
            exportItem.Address2 = el.cust_address1;
            exportItem.Ward = el.ward_name;
            exportItem.Group = el.group_name;
            exportItem.Due = el.cust_due;
            exportItem.Wallet = el.cust_wallet;
            exportItem.Tariff = el.cust_package_name;
            exportItem.Status = el?.cust_verification_status === 1 ? "Verified" : "Not Verified";

            dataToBeExported.push(exportItem);
          });
        setDataToBeExported(dataToBeExported);
        setExportLoader(false);
      });
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("cust_image", e.target.files[0]);
    axios
      .post(`${API_URL}customers/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setMaster({
            ...master,
            [`cust_image`]: response.data.file.filename,
          });
          // toastr.success("Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
    // setErrors({
    //   ...errors,
    //   ["imageError"]: "",
    // });
  };
  const verifyCustomer = (customer_id, cust_phone) => {
    axios
      .post(
        `${API_URL}customers/custVerify`,
        {
          customer_id: customer_id,
          localbody_id: currentLocalbody,
          cust_phone: cust_phone,
          status: 1,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status == 200) {
          toastr.success("Verified Successfully");
          handleTableData(
            searchValue,
            searchData?.from_date,
            searchData?.to_date,
            searchData?.ward_id,
            searchData?.group_id,
            searchData?.cust_type_id,
            searchData?.package_id,
            masterObj?.house_num,
            searchData?.status_id === 1 ? 1 : searchData?.status_id === 0 ? 0 : "",
            searchData?.noncorporative_status !== null ? searchData.noncorporative_status : "",
            indexOfFirstTransfer,
            indexOfLastTransfer
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const UnverifyCustomer = (customer_id, cust_phone) => {
    axios
      .post(
        `${API_URL}customers/custVerify`,
        {
          customer_id: customer_id,
          localbody_id: currentLocalbody,
          cust_phone: cust_phone,
          status: 0,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status == 200) {
          toastr.success("Verified Successfully");
          handleTableData(
            searchValue,
            searchData?.from_date,
            searchData?.to_date,
            searchData?.ward_id,
            searchData?.group_id,
            searchData?.cust_type_id,
            searchData?.package_id,
            masterObj?.house_num,
            searchData?.status_id === 1 ? 1 : searchData?.status_id === 0 ? 0 : "",
            searchData?.noncorporative_status !== null ? searchData.noncorporative_status : "",
            indexOfFirstTransfer,
            indexOfLastTransfer
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let preUpdateCustomer = (item) => {
    setCustomerIdTobeUpdated(item._id);
    axios
      .get(`${API_URL}customers` + "/" + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            let customerType = item.cust_type || {};
            let customertype = {
              label: customerType?.customer_type_name,
              value: customerType?._id,
              type: customerType?.type,
            };
            setSelectedCustomerType(customertype);

            item.cust_type = customerType?._id;

            item.typee = customerType?.type;
            let type_option = null;
            if (item.typee === 1) {
              type_option = {
                label: "Residentail",
                value: 1,
              };
            } else if (item.typee === 2) {
              type_option = {
                label: "Commercial",
                value: 2,
              };
            }
            setSelectedCategory(type_option);

            fetch_all_packages(currentLocalbody, item.cust_type);
            let desig = item.cust_designation || {};
            let designation = {
              label: desig?.desig_name,
              value: desig?._id,
            };
            setSelectedDesignation(designation);
            item.cust_designation = desig?._id;

            if (item.flatName) {
              let flatnamee = {
                label: item?.flatName?.flat_name,
                value: item?.flatName?._id,
              };
              setSelectedFlatName(flatnamee);
            } else {
              setSelectedFlatName(null);
            }

            let custDistrict = item.district || {};
            let district = {
              label: custDistrict?.district_name,
              value: custDistrict?._id,
            };
            setSelectedDistrict(district);
            item.district = custDistrict?._id;

            let custWard = item.ward || {};
            let ward = {
              label: custWard?.ward_name,
              value: custWard?._id,
            };
            setSelectedWard(ward);
            item.ward = custWard?._id;
            fetch_all_group(ward);

            let custPackage = item.cust_package_id || {};
            let packages = {
              label: custPackage.package_name,
              value: custPackage._id,
              status: custPackage.package_visit_month,
            };
            setSelectedPackage(packages);
            item.cust_package_id = custPackage?._id;

            if (packages.status == 4) {
              let cust_visit_day = item.cust_visit_day || {};
              setShowVisit(true);
              let cust_visit_day_Option = null;
              if (item?.cust_visit_day === 0) {
                cust_visit_day_Option = {
                  label: "Sunday",
                  value: 0,
                };
                let day = "Sunday";
              } else if (item?.cust_visit_day === 1) {
                cust_visit_day_Option = {
                  label: "Monday",
                  value: 1,
                };
                let day = "Monday";
              } else if (item?.cust_visit_day === 2) {
                cust_visit_day_Option = {
                  label: "Tuesday",
                  value: 2,
                };
                let day = "Tuesday";
              } else if (item?.cust_visit_day === 3) {
                cust_visit_day_Option = {
                  label: "Wednesday",
                  value: 3,
                };
                let day = "Wednesday";
              } else if (item?.cust_visit_day === 4) {
                cust_visit_day_Option = {
                  label: "Thursday",
                  value: 4,
                };
                let day = "Thursday";
              } else if (item?.cust_visit_day === 5) {
                cust_visit_day_Option = {
                  label: "Friday",
                  value: 5,
                };
                let day = "Friday";
              } else if (item?.cust_visit_day === 6) {
                cust_visit_day_Option = {
                  label: "Saturday",
                  value: 6,
                };
                let day = "Saturday";
              }
              setSelectedVisitday(cust_visit_day_Option);
            } else {
              setShowVisit(false);
            }

            let custBillingType = item.cust_billing_type || {};
            let Billingtype = {
              label: custBillingType?.name,
              value: custBillingType?._id,
            };
            setSelectedBillingtype(Billingtype);
            item.cust_billing_type = custBillingType?._id;

            let group_name = "";
            let group_id = "";

            item.cust_group_id &&
              item.cust_group_id.forEach((element) => {
                group_name = element.group_name;
                group_id = element._id;
              });
            let group = {
              label: group_name,
              value: group_id,
            };
            setSelectedGroup(group);
            item.cust_group_id = group_id;
          });
        setMaster(...result);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "cust_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "cust_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Phone",
        field: "cust_phone",
        sort: "asc",
        width: 200,
      },

      // {
      //   label: "District",
      //   field: "district_name",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Localbody",
      //   field: "localbody",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Ward",
        field: "ward_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Due",
        field: "cust_due",
        sort: "asc",
        width: 100,
      },
      {
        label: "Building No",
        field: "cust_house_num",
        sort: "asc",
        width: 100,
      },
      {
        label: "Verification Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };

  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const fetch_all_group = (selected) => {
    axios
      .get(`${API_URL}api/staff/groups?localbody_id=` + currentLocalbody + "&ward_id=" + selected.value, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var group_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.group_name,
                value: el._id,
              };
            });
        } else {
          group_data = [];
        }

        setGroupOptions([
          {
            options: group_data,
          },
        ]);
      });
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "category":
        setSelectedCategory(selected);
        setSelectedCustomerType(null);
        setMaster({
          ...master,
          ["category"]: selected.value,
          ["cust_type"]: "",
        });
        setErrors({
          ...errors,
          ["categoryError"]: "",
        });
        break;
      case "customerType":
        setSelectedCustomerType(selected);
        fetch_all_packages(currentLocalbody, selected.value);
        setMaster({
          ...master,
          ["cust_type"]: selected.value,
        });
        setErrors({
          ...errors,
          ["customertypeError"]: "",
        });
        break;
      case "ward":
        setSelectedWard(selected);
        setMaster({
          ...master,
          ["ward"]: selected.value,
        });
        setSelectedGroup(null);
        setSelectedFlatName(null);
        setErrors({
          ...errors,
          ["wardError"]: "",
        });
        fetch_all_group(selected);
        // fetch_flatname(selected);

        // axios
        //   .get(
        //     `${API_URL}api/staff/localbody/flatlist?localbody=${currentLocalbody}&ward=${selected.value}`,
        //     {
        //       headers: {
        //         "x-access-token": accessToken,
        //       },
        //     }
        //   )
        //   .then((res) => {
        //     let flat_data =
        //       res.data.data &&
        //       res.data.data.map((el) => {
        //         return {
        //           label: el?.flat_name,
        //           value: el?._id,
        //         };
        //       });
        //     setFlatOptions([{ options: flat_data }]);
        //   });
        break;
      case "group":
        setSelectedGroup(selected);
        setMaster({
          ...master,
          ["cust_group_id"]: selected.value,
        });
        setErrors({
          ...errors,
          ["groupError"]: "",
        });
        break;
      case "packages":
        setSelectedPackage(selected);
        setMaster({
          ...master,
          ["cust_package_id"]: selected.value,
        });
        setErrors({
          ...errors,
          ["packageError"]: "",
        });
        break;
      case "district":
        setSelectedDistrict(selected);
        setMaster({
          ...master,
          ["district"]: selected.value,
        });
        setErrors({
          ...errors,
          ["districtError"]: "",
        });
        break;
      case "designation":
        setSelectedDesignation(selected);
        setMaster({
          ...master,
          ["cust_designation"]: selected.value,
        });
        setErrors({
          ...errors,
          ["designationError"]: "",
        });
        break;
      case "billingtype":
        setSelectedBillingtype(selected);
        setMaster({
          ...master,
          ["cust_billing_type"]: selected.value,
        });
        setErrors({
          ...errors,
          ["billingtypeError"]: "",
        });
        break;
      case "flatName":
        setSelectedFlatName(selected);
        setMaster({
          ...master,
          ["flatName"]: selected.value,
        });
        break;
      case "visit_day":
        setSelectedVisitday(selected);
        setMaster({
          ...master,
          ["cust_visit_day"]: selected.value,
        });
        // setErrors({
        //   ...errors,
        //   ["districtError"]: "",
        // });
        break;
      default:
        break;
    }
  };
  const handleSearchSelectChange = (selected, name) => {
    // let cust_type_id = searchData?.cust_type_id ? searchData.cust_type_id : "";
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let package_id = searchData?.package_id ? searchData.package_id : "";
    // let status_id = searchData?.status_id ? searchData.status_id : "";
    // let date1 = searchData?.from_date ? searchData.from_date : "";
    // let date2 = searchData?.to_date ? searchData.to_date : "";
    switch (name) {
      case "category":
        setSelectedCategorySearch(selected)
        setSelectedCustomerTypeSearch(null)
        setSearchData({
          ...searchData,
          category: selected.value,
          cust_type_id: ""
        })
        break;
      case "customerType":
        setSelectedCustomerTypeSearch(selected);
        fetch_all_packages(currentLocalbody, selected.value, 1);
        setSelectedPackageSearch(null);
        setSearchData({
          ...searchData,
          cust_type_id: selected.value,
          package_id: "",
        });
        // fetch_all_packagesSearch(selected.value, currentLocalbody);
        // handleTableData(
        //   ward_id,
        //   group_id,
        //   selected.value,
        //   package_id,
        //   status_id
        // );
        break;
      case "ward":
        setSelectedWardSearch(selected);
        setSelectedGroupSearch(null);
        setSearchData({
          ...searchData,
          ward_id: selected.value,
          group_id: "",
        });
        axios
          .get(`${API_URL}api/staff/groups?localbody_id=` + currentLocalbody + "&ward_id=" + selected.value, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setSearchGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        // handleTableData(
        //   selected.value,
        //   "",
        //   cust_type_id,
        //   package_id,
        //   status_id
        // );
        break;
      case "group":
        setSelectedGroupSearch(selected);
        setSearchData({
          ...searchData,
          group_id: selected.value,
        });
        // handleTableData(
        //   ward_id,
        //   selected.value,
        //   cust_type_id,
        //   package_id,
        //   status_id
        // );
        break;
      case "package":
        setSelectedPackageSearch(selected);
        setSearchData({
          ...searchData,
          package_id: selected.value,
        });
        // handleTableData(
        //   ward_id,
        //   group_id,
        //   cust_type_id,
        //   selected.value,
        //   status_id
        // );
        break;
      case "cust_house_num":
        setSelectedBuilding(selected);
        setSearchData({
          ...searchData,
          cust_house_num: selected.value
        })
        break;
      case "status":
        setSelectedStatus(selected);
        setSearchData({
          ...searchData,
          status_id: selected.value,
        });
        break;

      case "nonstatus":
        setnonSelectedStatus(selected);
        setSearchData({
          ...searchData,
          noncorporative_status: selected.value,
        });
      // handleTableData(
      //   ward_id,
      //   group_id,
      //   cust_type_id,
      //   package_id,
      //   selected.value
      // );
      default:
        break;
    }
  };
  const deleteCustomerImage = () => {
    setMaster({
      ...master,
      [`cust_image`]: "",
    });
  };

  // if (selectedImage === null) {
  //   setErrors({
  //     ...errors,
  //     ["imageError"]: "Please select image",
  //   });
  //   return;
  // }

  const handleValidSubmit = (event, values) => {
    setIsLoading(true);
    // if (!master.cust_image) {
    //   toastr.error("Image is required");
    //   return;
    // }
    // if (selectedDistrict === null) {
    //   toastr.error("District is required");
    //   return;
    // }
    // if (selectedBillingtype === null) {
    //   toastr.error("Billing type is required");
    //   return;
    // }
    // if (selectedWard === null) {
    //   toastr.error("Ward is required");
    //   return;
    // }
    // if (selectedGroup === null) {
    //   toastr.error("Group is required");
    //   return;
    // }
    // if (selectedPackage === null) {
    //   toastr.error("Package is required");
    //   return;
    // }
    if (
      selectedDistrict === null ||
      selectedBillingtype === null ||
      selectedWard === null ||
      selectedGroup === null ||
      selectedPackage === null ||
      selectedCategory === null
      //  ||
      // !master.cust_image
    ) {
      let errorVal = errors;
      // if (!master.cust_image) {
      //   errorVal.imageError = "Please select image";
      // }
      if (selectedDistrict === null) {
        errorVal.districtError = "Please select district";
      }
      if (selectedBillingtype === null) {
        errorVal.billingtypeError = "Please select billing type";
      }
      if (selectedWard === null) {
        errorVal.wardError = "Please select ward";
      }
      if (selectedGroup === null) {
        errorVal.groupError = "Please select group";
      }
      if (selectedPackage === null) {
        errorVal.packageError = "Please select package";
      }
      if (selectedDesignation === null) {
        errorVal.designationError = "Please select designation";
      }
      if (selectedCustomerType === null) {
        errorVal.customertypeError = "Please select customer type";
      }
      if (selectedCategory === null) {
        errorVal.categoryError = "Please select category";
      }
      setErrors({
        ...errorVal,
      });
      setIsLoading(false);
    } else {
      if (customerIdTobeUpdated) {
        axios
          .put(`${API_URL}customers/update`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            setIsLoading(false);
            if (res.data.success === true) {
              toastr.success("Customer updated successfully");
              //reset();
              formRef.current.reset();
              setSelectedCustomerType(null);
              setSelectedBillingtype(null);
              setSelectedDesignation(null);
              setSelectedGroup(null);
              setSelectedDistrict(null);
              setSelectedWard(null);
              setSelectedPackage(null);
              setSelectedVisitday(null);
              setSelectedCategory(null);
              setShowVisit(false);
              handleTableData(
                searchValue,
                searchData?.from_date,
                searchData?.to_date,
                searchData?.ward_id,
                searchData?.group_id,
                searchData?.cust_type_id,
                searchData?.package_id,
                masterObj?.house_num,
                searchData?.status_id === 1 ? 1 : searchData?.status_id === 0 ? 0 : "",
                searchData?.noncorporative_status !== null ? searchData.noncorporative_status : "",
                indexOfFirstTransfer,
                indexOfLastTransfer
              );
              //fetch_export_data();
              setCustomerIdTobeUpdated(null);
              setMaster({
                localbody_name: getLocalbody(),
                cust_image: "",
                houseowner_name: "",
                shop_name: "",
                building_name: "",
                cust_latitude: "",
                cust_longitude: "",
                cust_address1: "",
                cust_address: "",
                cust_phone: "",
                cust_house_num: "",
              });
              setErrors({
                billingtypeError: "",
                groupError: "",
                packageError: "",
                wardError: "",
                districtError: "",
                customertypeError: "",
                designationError: "",
                categoryError: "",
                // imageError: "",
              });
            } else {
              toastr.error(res.data.message, "Failed to update customer");
              return;
            }
          })
          .catch((err) => {
            setIsLoading(false);
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}customers`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            setIsLoading(false);
            if (res.data.success === true) {
              toastr.success("Customer created successfully");
              formRef.current.reset();
              //reset();
              setSelectedCustomerType(null);
              setSelectedBillingtype(null);
              setSelectedDesignation(null);
              setSelectedGroup(null);
              setSelectedDistrict(null);
              setSelectedWard(null);
              setSelectedPackage(null);
              setSelectedVisitday(null);
              setSelectedCategory(null);
              setShowVisit(false);
              handleTableData(
                searchValue,
                searchData?.from_date,
                searchData?.to_date,
                searchData?.ward_id,
                searchData?.group_id,
                searchData?.cust_type_id,
                searchData?.package_id,
                masterObj?.house_num,
                searchData?.status_id === 1 ? 1 : searchData?.status_id === 0 ? 0 : "",
                searchData?.noncorporative_status !== null ? searchData.noncorporative_status : "",
                indexOfFirstTransfer,
                indexOfLastTransfer
              );
              //fetch_export_data();
              setMaster({
                localbody_name: getLocalbody(),
                cust_image: "",
                cust_phone: "",
                cust_house_num: "",
              });
              setErrors({
                billingtypeError: "",
                groupError: "",
                packageError: "",
                wardError: "",
                districtError: "",
                customertypeError: "",
                designationError: "",
                categoryError: "",
                //imageError: "",
              });
            } else {
              toastr.error(res.data.message, "Failed to create customer");
              return;
            }
          })
          .catch((err) => {
            setIsLoading(false);
            toastr.error(err.response.data.message);
            return;
          });
      }
    }

    // customerIdTobeUpdated
    //   ? dispatch(updateCustomers(master))
    //   : dispatch(addCustomers(master));

    //window.location.reload();
  };
  const reset = () => {
    setSearchData({
      from_date: "",
      to_date: "",
    });
    setMasterObj({})
    setSelectedCustomerTypeSearch(null);
    setSelectedWardSearch(null);
    setSelectedGroupSearch(null);
    setSelectedPackageSearch(null);
    setSelectedStatus(null);
    setnonSelectedStatus(null);
    resetPaginate();
    setSelectedCategorySearch(null)
    handleTableData(searchValue);
    //fetch_export_data();
  };
  const handleTableSearch = () => {
    setSearchLoader(true);
    resetPaginate();
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let cust_type_id = searchData?.cust_type_id ? searchData.cust_type_id : "";
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let package_id = searchData?.package_id ? searchData.package_id : "";
    let house_num = masterObj?.house_num ? masterObj?.house_num : "";
    let status_id = searchData?.status_id === 1 ? 1 : searchData?.status_id === 0 ? 0 : "";
    let noncorporative_status = searchData?.noncorporative_status !== null ? searchData.noncorporative_status : "";
    handleTableData(searchValue, date1, date2, ward_id, group_id, cust_type_id, package_id, house_num, status_id, noncorporative_status);
    //fetch_export_data(date1, date2, ward_id, group_id, cust_type_id, package_id, status_id, noncorporative_status);
  };

  const handleSearchChange = (event) => {
    setKeyWord(event.target.value);
    setMaster({ ...master, cust_phone: event.target.value });

    let data = {
      keyword: event.target.value,
      localbody_id: currentLocalbody,
      type: 1
    };

    // Clear previous timeout if any
    clearTimeout(typingTimeout);

    // Set timeout for 500ms
    const timeout = setTimeout(() => {
      axios
        .post(`${API_URL}customers/dashboard/mobile`, data, {
          headers: {
            "x-access-token": accessToken
          }
        })
        .then((res) => {
          let result = res.data.data;
          let dataValue = [];
          result &&
            result.forEach((el, index) => {
              dataValue.push(el);
            });
          setFilteredData(dataValue);
        });
    }, 500);

    // Store the timeout ID
    setTypingTimeout(timeout);
  };


  const handleChangeSearch = async (event) => {
    setBuildingNo(event.target.value);

    setMaster({ ...master, cust_house_num: event.target.value })

    let data = {
      keyword: event.target.value,
      localbody_id: currentLocalbody,
      type: 2,
      ward_id: selectedWard?.value
    }

    // clear prevoius timeOut
    clearTimeout(typeTimeout);

    // Set timeoout for 500ms
    const timeout = setTimeout(() => {
      axios
        .post(`${API_URL}customers/dashboard/mobile`, data, {
          headers: {
            "x-access-token": accessToken,
          },
        }).then((res) => {
          let result = res.data.data
          let dataValue = [];
          result &&
            result.map((el, index) => {

              dataValue.push(el)
            })
          setFilterData(dataValue)
        })
    }, 500)

    // Store the timeout
    setTypeTimeout(timeout)
  };

  function handleSelect(selectedValue) {
    setKeyWord(selectedValue)
    setMaster({ ...master, cust_phone: selectedValue })
    setFilteredData([])
  }

  function handleBuildSelect(selectedValue) {
    setBuildingNo(selectedValue)
    setMaster({ ...master, cust_house_num: selectedValue })
    setFilterData([])
  }

  function handleChangeInput(e) {
    let { name, value } = e.target
    setMasterObj({ ...masterObj, [name]: value })
  }


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}customers` + "/" + customerIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Customer deleted successfully");
                  //reset();
                  handleTableData(
                    searchValue,
                    searchData?.from_date,
                    searchData?.to_date,
                    searchData?.ward_id,
                    searchData?.group_id,
                    searchData?.cust_type_id,
                    searchData?.package_id,
                    masterObj?.house_num,
                    searchData?.status_id === 1 ? 1 : searchData?.status_id === 0 ? 0 : "",
                    searchData?.noncorporative_status !== null ? searchData.noncorporative_status : "",
                    indexOfFirstTransfer,
                    indexOfLastTransfer
                  );
                  //fetch_export_data();
                } else {
                  toastr.error("Failed to delete customer");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal isOpen={popupView} toggle={() => setPopupView(!popupView)} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Customer Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table id="product_available_day" className="table table-bordered dataTable">
              <tr>
                <th>Customer Type :</th>
                <td>{tableData?.cust_type}</td>
                <th>Name :</th>
                <td style={{ textAlign: "left" }}>{tableData?.cust_name}</td>
              </tr>
              <tr>
                <th>Reg No :</th>
                <td>{tableData?.cust_reg_no}</td>
                <th>phone :</th>
                <td style={{ textAlign: "left" }}>{tableData?.cust_phone}</td>
              </tr>
              <tr>
                <th>District :</th>
                <td>{tableData?.district}</td>
                <th>Localbody :</th>
                <td style={{ textAlign: "left" }}>{tableData?.localbody}</td>
              </tr>
              <tr>
                <th>Ward :</th>
                <td>{tableData?.ward}</td>
                <th>Staff :</th>
                <td style={{ textAlign: "left" }}>{tableData?.staff}</td>
              </tr>
              <tr>
                <th>Status :</th>
                {tableData?.cust_verification_status === 1 ? (
                  <td
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}>
                    {"Verified"}
                  </td>
                ) : (
                  <td
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}>
                    {"Not verified"}
                  </td>
                )}
                {/* <td>Building Image :</td>
                <td style={{ textAlign: "left"}}>
                  <img
                    style={{ width: "180px", height: "180px",paddingLeft: "0px" }}
                    src={image}
                  ></img>
                </td> */}
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Customers" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Category Type</Label>
                          <Select
                            name="category"
                            value={selectedCategory}
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Residential", value: 1 },
                              { label: "Commercial", value: 2 },
                            ]}
                            onChange={(selected) => {
                              handleSelectChange(selected, "category");
                            }}
                            validate={{ required: { value: true } }}
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedCategory === null ? errors.categoryError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Customer Type</Label>
                          <Select
                            name="cust_type"
                            value={selectedCustomerType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "customerType");
                            }}
                            options={customerType}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: true } }}
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedCustomerType === null ? errors.customertypeError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Designation</Label>
                          <Select
                            name="cust_designation"
                            value={selectedDesignation}
                            onChange={(selected) => {
                              handleSelectChange(selected, "designation");
                            }}
                            options={designationOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedDesignation === null ? errors.designationError : ""}
                          </p>
                        </div>
                      </Col>
                      {selectedCustomerType?.label === "House" && selectedDesignation?.label === "Rent" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Owner Name</Label>
                            <AvField
                              name="houseowner_name"
                              placeholder="Owner Name"
                              className="form-control"
                              value={master?.houseowner_name}
                              onChange={handleInputChange}
                              type="text"
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Name</Label>
                          <AvField
                            name="cust_name"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={master?.cust_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      {selectedCustomerType?.type === 2 ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Commercial name</Label>
                            <AvField
                              name="shop_name"
                              placeholder="Commercial Name"
                              type="text"
                              className="form-control"
                              value={master?.shop_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedCustomerType?.type === 2 ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Building Name</Label>
                            <AvField
                              name="building_name"
                              placeholder="Building Name"
                              type="text"
                              className="form-control"
                              value={master?.building_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedCustomerType?.label === "FLAT" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Flat Name</Label>
                            <Select
                              name="flatName"
                              placeholder="Flat Name"
                              type="text"
                              onChange={(selected) => {
                                handleSelectChange(selected, "flatName");
                              }}
                              options={flatOptions}
                              value={selectedFlatname}
                              classNamePrefix="select2-seletction"
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Mobile Number
                          </Label>
                          <AvField
                            name="cust_phone"
                            placeholder="Mobile Number"
                            type="number"
                            errorMessage="Enter valid 10 digit number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            // value={keyWord}
                            value={master?.cust_phone}
                            // onChange={handleInputChange}
                            onChange={handleSearchChange}
                            // onBlur={() => setFilteredData([])} // Close dropdown on blur
                            minLength={10}
                            maxLength={10}
                          />
                        </div>
                        {filteredData.length != 0 && (
                          <div
                            style={{
                              top: "60px",
                              width: "268px",
                              maxHeight: "177px",
                              backgroundColor: "white",
                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              overflow: "hidden",
                              overflowY: "auto",
                              position: "absolute",
                              zIndex: "100"
                            }}
                          >
                            {filteredData.map((value, key) => {
                              return (
                                <a
                                  className="test_hover"
                                  target="_self"
                                  onClick={() => handleSelect(value.cust_phone)}
                                >
                                  <p
                                    className="drop_hover"
                                    style={{ padding: "5px 15px" }}
                                  >
                                    {value?.cust_phone}{" "}
                                  </p>
                                </a>
                              )
                            })}
                          </div>
                        )}
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Landline Number</Label>
                          <AvField
                            name="cust_landline_no"
                            placeholder="Landline Number"
                            type="phone"
                            errorMessage="Enter valid 11 digit number"
                            className="form-control"
                            validate={{
                              minLength: { value: 11 },
                              maxLength: { value: 11 },
                            }}
                            id="validationCustom03"
                            value={master?.cust_landline_no}
                            onChange={handleInputChange}
                          // minLength={11}
                          // maxLength={11}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Whatsapp Number</Label>
                          <AvField
                            name="cust_whatsapp_no"
                            placeholder="Whatsapp Number"
                            type="number"
                            errorMessage="Enter valid 10 digit number"
                            className="form-control"
                            validate={{
                              minLength: { value: 10 },
                              maxLength: { value: 10 },
                            }}
                            id="validationCustom01"
                            value={master?.cust_whatsapp_no}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Email</Label>
                          <AvField
                            name="cust_email"
                            placeholder="Email"
                            type="email"
                            errorMessage="Enter valid email"
                            className="form-control"
                            validate={{ email: true }}
                            id="validationCustom01"
                            value={master?.cust_email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Address 1</Label>
                          <AvField
                            name="cust_address"
                            placeholder="Address 1"
                            type="text"
                            errorMessage="Enter Address 1"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.cust_address}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Address 2</Label>
                          <AvField
                            name="cust_address1"
                            placeholder="Address 2"
                            type="text"
                            // errorMessage="Enter Address1"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.cust_address1}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Number of Members</Label>
                          <AvField
                            name="cust_no_members"
                            placeholder="Number of Members"
                            type="number"
                            min={1}
                            // errorMessage="Enter number of members"
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.cust_no_members}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="district"
                            value={selectedDistrict}
                            onChange={(selected) => {
                              handleSelectChange(selected, "district");
                            }}
                            options={districtOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedDistrict === null ? errors.districtError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward"
                            value={selectedWard}
                            onChange={(selected) => {
                              handleSelectChange(selected, "ward");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedWard === null ? errors.wardError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Packages</Label>
                          <Select
                            name="cust_package_id"
                            value={selectedPackage}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected, "packages");
                            // }}
                            onChange={(value) => {
                              handleSelectedPackage(value);
                            }}
                            options={packageOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedPackage === null ? errors.packageError : ""}
                          </p>
                        </div>
                      </Col>
                      {showVisit === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Visit Day</Label>
                            <Select
                              name="cust_visit_day"
                              value={selectedVisitday}
                              onChange={(selected) => {
                                handleSelectChange(selected, "visit_day");
                              }}
                              options={[
                                {
                                  label: "Sunday",
                                  value: 0,
                                },
                                {
                                  label: "Monday",
                                  value: 1,
                                },
                                {
                                  label: "Tuesday",
                                  value: 2,
                                },
                                {
                                  label: "Wednesday",
                                  value: 3,
                                },
                                {
                                  label: "Thursday",
                                  value: 4,
                                },
                                {
                                  label: "Friday",
                                  value: 5,
                                },
                                {
                                  label: "Saturday",
                                  value: 6,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                            {/* <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedWard === null ? errors.wardError : ""}
                            </p> */}
                          </div>
                        </Col>
                      ) : null}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Group</Label>
                          <Select
                            name="cust_group_id"
                            value={selectedGroup}
                            onChange={(selected) => {
                              handleSelectChange(selected, "group");
                            }}
                            options={groupOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedGroup === null ? errors.groupError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Building No
                          </Label>
                          <AvField
                            name="cust_house_num"
                            placeholder="House No"
                            type="text"
                            errorMessage="Enter House no"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.cust_house_num}
                            // onChange={handleInputChange}
                            // value={BuildingNo}
                            onChange={handleChangeSearch}
                          // onBlur={() => setFilterData([])} // Close dropdown on blur
                          />
                        </div>
                        {filterData.length != 0 && (
                          <div
                            style={{
                              top: "60px",
                              width: "268px",
                              maxHeight: "177px",
                              backgroundColor: "white",
                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              overflow: "hidden",
                              overflowY: "auto",
                              position: "absolute",
                              zIndex: "100"
                            }}
                          >
                            {filterData.map((value, key) => {
                              return (
                                <a
                                  className="test_hover"
                                  target="_self"
                                  onClick={() => handleBuildSelect(value.cust_house_num)}
                                >
                                  <p
                                    className="drop_hover"
                                    style={{ padding: "5px 15px" }}
                                  >
                                    {value?.cust_house_num}{" "}
                                  </p>
                                </a>
                              )
                            })}
                          </div>
                        )}
                      </Col>

                      {selectedCustomerType?.label === "Flat" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Select
                              name="flatName"
                              placeholder="Flat Name"
                              onChange={(selected) => {
                                handleSelectChange(selected, "flatName");
                              }}
                              options={flatOptions}
                              value={selectedFlatname}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Latitude</Label>
                          <AvField
                            name="cust_latitude"
                            placeholder="Latitude"
                            type="number"
                            // errorMessage="Enter Latitude"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.cust_latitude}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Longitude</Label>
                          <AvField
                            name="cust_longitude"
                            placeholder="Longitude"
                            type="number"
                            // errorMessage="Enter Longitude"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.cust_longitude}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Building Image
                          </Label>
                          <AvField
                            name="cust_image"
                            id="cust_image"
                            type="file"
                            errorMessage="Upload Image"
                            className="form-control"
                            onChange={uploadImage}
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Billing Type</Label>
                          <Select
                            name="cust_billing_type"
                            value={selectedBillingtype}
                            onChange={(selected) => {
                              handleSelectChange(selected, "billingtype");
                            }}
                            options={[
                              {
                                label: "Monthly",
                                value: "61f775c6aef04ceabc89eae7",
                              },
                              {
                                label: "Spot",
                                value: "61f77607aef04ceabc89eae9",
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedBillingtype === null ? errors.billingtypeError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Building Image</Label>
                          {master?.cust_image ? (
                            <div className="img-wraps">
                              {master.cust_image && master.cust_image.startsWith("http") ? (
                                <img alt="" width="150" height="150" src={`${master.cust_image}`} />
                              ) : (
                                <img alt="" width="150" height="150" src={`${API_URL}uploads/customers_images/${master.cust_image}`} />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteCustomerImage}
                                style={{ width: "150px" }}>
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField name="cust_image" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
                          )}
                          {/* {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )} */}
                          {/* <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master.cust_image ? errors.imageError : ""}
                          </p> */}
                        </div>
                      </Col>
                    </Row>

                    {customerIdTobeUpdated ? (
                      <Button
                        color="primary"
                        type="submit"
                        //disabled={addingCustomer ? true : false}
                        disabled={isLoading ? "disabled" : ""}>
                        {isLoading ? "Updating" : "Update"}
                      </Button>
                    ) : (
                      <Button color="primary" type="submit" disabled={isLoading ? "disabled" : ""}>
                        {isLoading ? "Submitting" : "Submit"}
                      </Button>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row className="mb-3">
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={searchData?.from_date}
                            onChange={handleDate}
                            max={getDate(new Date())}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={searchData?.to_date}
                            min={searchData?.from_date}
                            onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md='2'>
                        <div className="mb-3">
                          <Label>Category Type</Label>
                          <Select
                            name="category"
                            value={selectedCategorySearch}
                            options={[
                              { label: "Residential", value: 1 },
                              { label: "Commercial", value: 2 }
                            ]}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "category")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Customer Type</Label>
                          <Select
                            name="cust_type_id"
                            value={selectedCustomerTypeSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "customerType");
                            }}
                            options={customerType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward_id"
                            value={selectedWardSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "ward");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Group</Label>
                          <Select
                            name="group_id"
                            value={selectedGroupSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "group");
                            }}
                            options={searchGroupOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Package</Label>
                          <Select
                            name="package_id"
                            value={selectedPackageSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "package");
                            }}
                            options={packageSearchOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Building No
                          </Label>
                          <AvField
                            name="house_num"
                            placeholder="House No"
                            type="number"
                            min={0}
                            className="form-control"
                            id="validationCustom01"
                            value={masterObj?.house_num}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Verification Status</Label>
                          <Select
                            name="verification_status"
                            value={selectedStatus}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "status");
                            }}
                            options={[
                              {
                                label: "Verified",
                                value: 1,
                              },
                              {
                                label: "Not Verified",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="noncorporative_status"
                            value={selectednonStatus}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "nonstatus");
                            }}
                            options={[
                              {
                                label: "Active",
                                value: 0,
                              },
                              {
                                label: "Non co-operative",
                                value: 2,
                              },
                              {
                                label: "Permanent Lock",
                                value: 3,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div style={{ display: "flex" }}>
                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                            <Button color="primary" type="submit" onClick={() => handleTableSearch()}>
                              Search
                            </Button>
                          </div>

                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                            <Button color="danger" type="reset" onClick={() => reset()}>
                              Reset
                            </Button>
                          </div>
                          {showExport ? (
                            <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                              <Button color="success" type="submit" disabled={exportLoader ? "disabled" : ""}>
                                {/* <i className="mdi mdi-microsoft-excel"></i> */}
                                <CSVLink data={dataToBeExported} filename={"Customers_" + getLocalbodyName() + ".xls"} style={{ color: "white" }}>
                                  {exportLoader ? (
                                    <>
                                      Loading <i className="mdi mdi-spin mdi-loading"></i>
                                    </>
                                  ) : (
                                    "Export"
                                  )}
                                </CSVLink>
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                  </AvForm>
                  {/* {searchLoader ? (
                    <Row>
                      <Col md="12">
                        <Loader
                          show={searchLoader}
                          type="box"
                          message="Loading Data"
                        />
                      </Col>
                    </Row>
                  ) : null} */}
                  <Row
                  // style={{ display: searchLoader ? "none" : "block" }}
                  >
                    <Col md="12">
                      {searchLoader && <LinearProgress />}
                      <MDBDataTable
                        id="customerTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={false}
                        isLoading={searchLoader}
                        disableRetreatAfterSorting={true}
                        paging={false}
                        onSearch={(value) => {
                          setValue(value);
                        }}
                        // onSearch={debounce(handleSearch, 500)}
                        entries={PerPageCount}
                      />
                      {totalPages > 20 && (
                        <div className="pagination-div">
                          <div className="page-details">
                            Showing {indexOfFirstTransfer + 1} to {indexOfFirstTransfer + details.length} of {totalPages} entries
                          </div>
                          <Pagination
                            color="standard"
                            shape="rounded"
                            defaultPage={1}
                            count={Math.ceil(totalPages / PerPageCount)}
                            page={currentPage}
                            onChange={paginate}
                            info={true}
                            size="large"
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(Customers));

Customers.propTypes = {
  error: PropTypes.any,
  users: PropTypes.array,
};
