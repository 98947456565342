import React, { useState } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Label, Modal, Row } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ReactSelect from "react-select";

const Index = () => {
  const [verifyModal, setVerifyModal] = useState(false);
  const handleCloseModal = () => setVerifyModal(false);
  const [master, setMaster] = useState([]);
  const [bucket, setBucket] = useState([
    { item: "Thermocol", weight: 12, bag: 2 },
    { item: "Plastics", weight: 6, bag: 1 },
    { item: "Chappals", weight: 12, bag: 2 },
  ]);
  const handleValueChange = (name, value, index) => {
    const data = [...bucket];
    data[index][name] = value;
    if (!bucket[index].editable) {
      setMaster((prev) => [
        ...prev,
        {
          item: data[index].item,
          weight: data[index].weight,
          bag: data[index].bag,
        },
      ]);
    }
    data[index].editable = true;
    setBucket(data);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 100,
      },
      {
        label: "Time",
        field: "dropTime",
        width: 100,
      },

      {
        label: "Unique Id",
        field: "uniqueid",
        width: 100,
      },
      {
        label: "Warehouse",
        field: "warehouse",
        width: 100,
      },
      // {
      //   label: "Item",
      //   field: "item",
      //   width: 100,
      // },
      {
        label: "Weight",
        field: "quantity",
        width: 50,
      },
      {
        label: "Bag",
        field: "bag",
        width: 50,
      },
      {
        label: "Drop Person",
        field: "dropPerson",
        width: 100,
      },
      {
        label: "Comments",
        field: "comments",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [
      {
        id: 1,
        date: "09-12-2024",
        warehouse: "Main",
        bag: "2",
        item: "Item X",
        kg: 50,
        uniqueid: "NLK023475",
        dropPerson: "John Doe",
        personNo: 101,
        action: (
          <>
            <Button
              color="primary"
              onClick={() => setVerifyModal(true)}
              style={{
                marginRight: "5px",
                fontSize: "smaller",
                padding: "3px 16px",
              }}
            >
              Verify
            </Button>
            <Button
              color="danger"
              size="sm"
              style={{ fontSize: "smaller", padding: "3px 16px" }}
            >
              Reject
            </Button>
          </>
        ),
        location: "Loading Dock A",
        status: "Completed",
        dropTime: "14:30",
        quantity: 20,
        comments: "Dropped without issues.",
      },
      {
        id: 2,
        date: "01-12-2024",
        warehouse: "Mini",
        item: "Item Y",
        kg: 75,
        bag: "5",

        uniqueid: "NLK023356",
        dropPerson: "Jane Smith",
        personNo: 102,
        action: (
          <>
            <Button
              color="primary"
              style={{
                marginRight: "5px",
                fontSize: "smaller",
                padding: "3px 16px",
              }}
            >
              Verify
            </Button>
            <Button
              color="danger"
              size="sm"
              style={{ fontSize: "smaller", padding: "3px 16px" }}
            >
              Reject
            </Button>
          </>
        ),
        location: "Receiving Area",
        status: "Pending",
        dropTime: "09:00",
        quantity: 15,
        comments: "Awaiting inspection.",
      },
    ],
  };
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Drops" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <ReactSelect
                            name="wareHouse"
                            options={[
                              { label: "Main MCF", value: "Main MCF" },
                              { label: "Mini MCF", value: "Mini MCF" },
                              { label: "RRF", value: "RRF" },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Ware House</Label>
                          <ReactSelect
                            name="wareHouse"
                            options={[
                              { label: "Kannur Main MCF", value: "Main MCF" },
                              { label: "Kannur Mini MCF", value: "Mini MCF" },
                              { label: "Kannur RRF", value: "RRF" },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">From Date</Label>
                          <AvField name="fromDate" type="date" />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">To Date</Label>
                          <AvField name="toDate" type="date" />
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="eventTableId"
                    responsive
                    bordered
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          isOpen={verifyModal}
          size="lg"
          toggle={() => {
            handleCloseModal();
          }}
          centered={true}
        >
          <div className="modal-header">
            {/* <h5 className="modal-title mt-0">Complaint </h5> */}
            <button
              type="button"
              onClick={() => {
                setVerifyModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <AvForm>
              <Row style={{ paddingLeft: "15px" }}>
                <div class="col-md-12">
                  <div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Total Weight</th>
                          <th>Total Bag</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bucket.map((item, index) => (
                          <tr>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) =>
                                  handleValueChange(
                                    "item",
                                    e.target.value,
                                    index
                                  )
                                }
                                value={item.item}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={item.weight}
                                onChange={(e) =>
                                  handleValueChange(
                                    "weight",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={item.bag}
                                onChange={(e) =>
                                  handleValueChange(
                                    "bag",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {master.length > 0 ? (
                  <>
                    <Label>
                      Disputed Qantity <input checked={master.length > 0 } type="checkbox" />
                    </Label>
                    {master.map((item) => {
                      return (
                        <Row>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                value={item.item}
                                name="quantity"
                                placeholder="Item"
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                value={item.weight}
                                name="quantity"
                                placeholder="Total Weight"
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                value={item.bag}
                                name="quantity"
                                placeholder="Bag"
                              />
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
                <Col md="4">
                  <div className="mb-3">
                    <Label>Comments</Label>
                    <AvField name="quantity" placeholder="Comments" />
                  </div>
                </Col>
                <Col md="3" style={{ paddingTop: "25px" }}>
                  <Button color="primary" className="me-2" type="submit">
                    {master.length > 0 ? "Dispute" : "Verify"}
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Index;
